.userDetails-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
}
    .userDetails-container-main {
        display: flex;
        flex-direction: column;
        margin-left: 103px;
        width: 100%;
    }
        .userDetails-container-main-hedear {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            position: fixed;
            background-color: transparent;
            width: 91%;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 30px;
            z-index: 4;
        }
            .userDetails-container-main-hedear-iconWrapper {
                margin-right: 270px;
            }
        .userDetails-container-main-body {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
            margin-top: 90px;
            margin-left: 20px;
            width: 97.5%;
        }
            .userDetails-container-main-body-title {
                color: var(--gray700);
                margin: 0;
            }
            .userDetails-container-main-body-volver {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                margin-top: 10px;
                margin-bottom: 20px;
                width: fit-content;
            }
                .userDetails-container-main-body-volver-text {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 0.8rem;
                    color: var(--primary500);
                    margin: 0;
                    width: fit-content;
                }