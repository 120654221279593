@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./fonts/Lato-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./fonts/Lato-Black.ttf) format('opentype');
}

html {
  font-size: 1vw;
}
  @media only screen and (max-width: 37.5em) {
      html {
          font-size: 4.2vw;
      }}
  @media only screen and (max-width: 37.5em) and (min-width: 400px) {
      html {
          font-size: 3.5vw;
      }}
  @media only screen and (max-width: 900px) and (min-width: 37.5em) {
      html {
          font-size: 1.6vw;
      }}
  @media only screen and (max-width: 1200px) and (min-width: 900px) {
      html {
          font-size: 1.2vw;
      }}
  @media only screen and (min-width: 1900px) {
      html {
          font-size: unset;
      }}

:root {
  --primary500: #7963E0;
  --primary400: #5741BB;
  --primary700: #382494;
  --primary200: #c7c2ef;

  --secondary500: #FF4179;
  --secondary700: #DF2159;

  
  

  --accent300: #B9D7D9;
  --accent400: #EDCCA3;
  --accent500: #F0B49E;

  --successPrimary: #27AE60;
  --errorPrimary: #EF052E;
  

  --successPrimary: #008744;
  --successSecondary: #00A650;
  --warningPrimary: #E6540B;
  --warningSecondary: #FF7733;
  --errorPrimary: #D12440;
  --errorSecondary: #F23D4F;
  --pendingPrimary: #F8C511;

  --primary200: #C9C1F3;
  --primary400: #9482E6;

  --secondary500: #FF4179;
  --secondary700: #DF2159;

  --white: #FFFFFF;
  --gray50: #F4F4F4;
  --gray100: #E6E6E6;
  --gray200: #C4C4C4;
  --gray300: #999999;
  --gray600: #4D4D4D;
  --gray400: #858585;
  --gray700: #333333;
}