.alertComponent-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
    .alertComponent-container-text {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: var(--gray700);
        margin: 0;
    }
    /* Tooltip container */
    .alertComponent-container-tooltip {
        position: relative;
        display: inline-block;
    }
    
    /* Tooltip text */
    .alertComponent-container-tooltip .alertComponent-container-tooltiptext {
        width: 120px;
        bottom: 100%;
        left: 50%;
        margin-left: -55px;
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-bottom: 10px;
    
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .alertComponent-container-tooltip:hover .alertComponent-container-tooltiptext {
        visibility: visible;
    }
    .alertComponent-container-tooltip .alertComponent-container-tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }