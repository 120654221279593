.customTextInput-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
}
    .customTextInput-container-label {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 5px;
    }
        .customTextInput-container-label-text {
            color: var(--gray300);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        /* Tooltip container */
        .customTextInput-container-label-tooltip {
            position: relative;
            display: inline-block;
        }
        
        /* Tooltip text */
        .customTextInput-container-label-tooltip .customTextInput-container-label-tooltiptext {
            width: 250px;
            bottom: 100%;
            left: 100%;
            margin-left: 5px;
            margin-bottom: -35px;
            visibility: hidden;
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
        
            /* Position the tooltip text - see examples below! */
            position: absolute;
            z-index: 9999;
        }
        
        /* Show the tooltip text when you mouse over the tooltip container */
        .customTextInput-container-label-tooltip:hover .customTextInput-container-label-tooltiptext {
            visibility: visible;
        }
        .customTextInput-container-label-tooltip .customTextInput-container-label-tooltiptext::after {
            content: " ";
            position: absolute;
            top: 50%; /* At the bottom of the tooltip */
            right: 100%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent black transparent transparent;
            z-index: 9999;
        }
    .customTextInput-container-inputContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid var(--gray200);
        box-sizing: border-box;
        border-radius: 15px;
        background-color: var(--white);
        padding: 10px;
        width: 100%;
    }
    .customTextInput-container-inputContainerFocus {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid var(--primary500);
        box-sizing: border-box;
        border-radius: 15px;
        background-color: var(--white);
        padding: 10px;
        width: 100%;
    }
        .customTextInput-container-inputContainer-input {
            border: none;
            outline: none;
            color: var(--gray700);
            margin: 0;
            padding: 0;
            width: 95%;
            height: 23px;
        }
            .customTextInput-container-inputContainer-input::placeholder {
                color: var(--gray300);
            }
                .customTextInput-container-inputContainer-input:focus::placeholder {
                    color: var(--gray300);
                }
        .customTextInput-container-inputContainer-icon {
            height: 20px;
            width: 20px;
        }
    .customTextInput-container-error {
        color: var(--errorPrimary);
        padding: 0;
        margin: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .customTextInput-container-underText {
        color: var(--gray300);
        padding: 0;
        margin: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4px;
    }
    .customTextInput-container-underline {
        color: var(--gray700);
        padding: 0;
        margin: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .customTextInput-container-textCopy {
        color: var(--successPrimary);
        margin-left: auto;
        margin-right: 10px;
        transition: all 150ms ease;
    }

.customTextInput-modalContainer {

}
    .customTextInput-modalContainer-close {
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

.loading-customTextInput {
    display: inline-block;
    position: relative;
    width: 23px;
    height: 23px;
  }
  .loading-customTextInput:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 4px;
    box-sizing: border-box;
    border: 9px solid var(--primary500);
    border-color: var(--primary500) transparent var(--primary500) transparent;
    animation: loading-customTextInput 1.2s infinite;
  }
  @keyframes loading-customTextInput {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }