body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 18px;
  background-color: var(--gray50);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background: #888;
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

h1 {
  font-style: normal;
  font-size: 2.5rem;
  line-height: 3rem;
  margin: 0;
}

h2 {
  font-style: normal;
  font-size: 2.125rem;
  line-height: 2.75rem;
  margin: 0;
}

h3 {
  font-style: normal;
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin: 0;
}

h4 {
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
}

h5 {
  font-style: normal;
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 0;
}

h6 {
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
}

p {
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0;
}

.large {
  font-style: normal;
  font-size: 1rem;
  line-height: 1.313rem;
}

.medium {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;
}

.small {
  font-style: normal;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.xSmall {
  font-style: normal;
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}