.DateBetweenComponent-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-right: 10px;
}
.DateBetweenComponent-container-text {
  color: var(--gray600);
  margin-bottom: 2px;
  font-size: 14px;
}
.DateBetweenComponent-container-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid var(--gray300);
  background: var(--white);
  padding: 10px;
  width: 100%;
}
.DateBetweenComponent-container-input::placeholder {
  color: var(--gray300);
  font-weight: 300;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;
}
.DateBetweenComponent-container-inputFocus {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid var(--primary500);
  background: var(--white);
  padding: 10px;
  width: 100%;
  outline: 1px solid var(--primary500);
}
.DateBetweenComponent-container-error {
  color: var(--errorPrimary);
}
