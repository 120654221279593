.userData-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .userData-container-data {
        display: flex;
        flex-direction: column;
        width: 75%;
    }
        .userData-container-data-title {
            color: var(--gray700);
            margin: 0;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        .userData-container-data-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%
        }
            .userData-container-data-section > * {
                width: 31%;
                margin-bottom: 20px;
                margin-right: 20px;
            }
            .userData-container-data-section-double {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 31%;
                margin-bottom: 10px;
            }
                .userData-container-data-section-double > * {
                    width: 47.5%;
                }
            .userData-container-data-section-status {
                display: flex;
                flex-direction: column;
                width: fit-content;
            }
                .userData-container-data-section-status-label {
                    color: var(--gray300);
                    margin: 0;
                    padding: 0;
                    margin-bottom: 5px;
                }
                .userData-container-data-section-status-data {
                    color: var(--gray700);
                    padding: 2.5px 5px;
                    border-radius: 5px;
                }