.tableContent-container {
    width: 100%;
    padding-bottom: 25px;
}
@media only screen and (max-width: 1200px) and (min-width: 1000px) {
    .tableContent-container {
        padding-right: 200px;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 900px) {
    .tableContent-container {
        padding-right: 300px;
    }
}
@media only screen and (max-width: 800px) and (min-width: 700px) {
    .tableContent-container {
        padding-right: 400px;
    }
}
@media only screen and (max-width: 700px) and (min-width: 600px) {
    .tableContent-container {
        padding-right: 500px;
    }
}
    .tableContent-container-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 40px;
    }
        .tableContent-container-empty-title {
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            color: var(--gray700);
            margin: 0;
            margin-top: 20px;
        }
        .tableContent-container-empty-text {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: var(--gray300);
            margin: 0;
            margin-top: 20px;
            text-align: center;
        }
    .tableContent-container-pageContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0;
        margin-left: 20px;
    }
        .tableContent-container-pageContainer-pages {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 auto;
        }
            .tableContent-container-pageContainer-pages-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .tableContent-container-pageContainer-pages-text {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: var(--primary500);
                margin: 0 10px;
            }
            .tableContent-container-pageContainer-pages-number {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: var(--gray300);
                margin: 0 5px;
            }
        .tableContent-container-pageContainer-totalText {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            color: var(--gray300);
            margin: 0;
        }
    #main-table {
        border-collapse: collapse;
        width: 100%;
    }
        .table-rowHover {
            cursor: pointer;
        }
        .table-rowHover:hover {
            background-color: var(--primary200);
        }
        #main-table th {
            max-width: 15%;
            height: 58px;
            text-align: left;
            background-color: var(--gray100);
            color: var(--gray300);
            padding: 0 10px;
            font-size: 12px;
        }
        #main-table td {
            text-align: left;
            width: 15%;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: var(--gray200);
            height: 50px;
            padding: 0 10px;
        }
    .props.goToDetails {

    }