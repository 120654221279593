.genericTableAndFilters-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 120px;
}
.genericTableAndFilters-container-buttonWrapper {
  margin-left: auto;
  margin-right: 10px;
  width: 20%;
}
.genericTableAndFilters-container-title {
  color: var(--gray700);
  margin-left: 20px;
}
.genericTableAndFilters-container-table {
  width: 100%;
  margin-top: 20px;
  overflow-x: scroll;
}
